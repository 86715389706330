<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('make_up_exam_student_list')"
                        :isColumns="true"
                        :is-filter="false"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-' + key"
                                :name="'checkbox-' + key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('make_up_exam_student_list')"
                              :isColumns="true"
                              :is-filter="false"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-' + key"
                                :name="'checkbox-' + key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <div class="d-flex justify-content-end mb-2">
                <json-excel type="xls"
                            :data="examExcel"
                            :fields="examFieldsJson">
                    <b-button variant="success">
                        <i class="ri-file-excel-2-line mr-2"></i> {{ $t('excel') }}
                    </b-button>
                </json-excel>
            </div>

            <datatable
                :title="$t('exams')"
                :isLoading="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable"
            >
            </datatable>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import Datatable from "@/components/datatable/Datatable";
import MakeUpExamService from "@/services/MakeUpExamService";
import JsonExcel from "vue-json-excel";



export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        Datatable,
        JsonExcel
    },
    metaInfo() {
        return {
            title: this.$t("make_up_exam_student_list"),
        };
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: this.toUpperCase("course_name"),
                        field: ("student_course.course_name"),
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("course_code"),
                        field: "student_course",
                        formatFn:(value)=>{
                            return this.getObjectValue(value,'course_code')
                        },
                        hidden: true,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("student_number"),
                        field: 'student_program.student',
                        formatFn:(value)=>{
                            return this.getObjectValue(value,'student_number')
                        },
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("name"),
                        field: 'student_program.student.person.name',
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("surname"),
                        field: 'student_program.student.person.surname',
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("final_letter_grade"),
                        field: 'final_letter_grade',
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("letter_grade"),
                        field: "letter_grade",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("is_take_exam"),
                        field: "is_take_exam",
                        formatFn:(value)=>{
                            return (value ? this.$t('yes') : this.$t('no'));
                        },
                        hidden: false,
                        sortable: false,
                    },
                ],
                rows: [],
                queryParams:{
                    page:1,
                    limit:20,
                    sort:'-id'
                },
                showTable: false,
            },
            student:{},
            examExcel:[],
            examFieldsJson: {
                [this.$t('course_name')]: 'course_name',
                [this.$t('student_number')]: 'student_number',
                [this.$t('name')]: 'name',
                [this.$t('surname')]: 'surname',
                [this.$t('final_letter_grade')]: 'final_letter_grade',
                [this.$t('letter_grade')]: 'letter_grade',
                [this.$t('is_take_exam')]: 'is_take_exam',
            },
        };
    },
    created() {
        this.getRows();
    },
    methods: {
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            MakeUpExamService.getInstructor()
                .then((response) => {
                    const {data}=response.data

                    this.datatable.rows = Object.values(data).flat()
                    this.datatable.rows.forEach(row => {
                        this.examExcel.push({
                            course_name: row.student_course.course_name,
                            student_number: row.student_program.student.student_number,
                            name: row.student_program.student.person.name,
                            surname: row.student_program.student.person.surname,
                            final_letter_grade: row.final_letter_grade,
                            letter_grade: row.letter_grade,
                            is_take_exam: row.is_take_exam ? this.$t('yes') : this.$t('no')

                        })
                    })
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        }
    },
};
</script>
